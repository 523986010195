<template>
  <div v-if="banner">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('banner.update')"
      :title="$t('banner.update')"
      @submitPressed="submitPressed"
    />
    <banner-form
      :banner-obj="banner"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import BannerForm from "@/views/Admin/Banners/components/BannerForm.vue";

export default {
  components: {
    TitleBar,
    BannerForm,
  },
  data() {
    return {
      action: null,
      banner: null,
    };
  },
  created() {
    this.loadBanner();
  },
  methods: {
    loadBanner() {
      const id = this.$route.params.id;
      this.$Banners.getResource({ id }).then((response) => {
        if (response.status === 200) {
          this.banner = response.data;
          this.banner.placement = {
            label: this.$helper.getEnumTranslation(
              "placement",
              this.banner.placement,
              this.$i18n.locale
            ),
            id: this.banner.placement,
          };
          const temp = []
          if(this.banner.slides && this.banner.slides.length) {
            for (const id of this.banner.position) {
              const slide = this.banner.slides.find(banner => banner['@id'] === id)
              if(slide) {
                temp.push(slide)
              }
            }
            this.banner.slides = temp
          }
        }
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
